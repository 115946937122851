import { v4 as uuid4 } from 'uuid';

import { fromExtent } from 'ol/geom/Polygon';
import Draw, { createRegularPolygon } from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';

import { GEOMETRY_TYPE_STRING, LAYER_INDEX, MAP_LAYERS, MEASUREMENT_SHAPE } from '../../../Constants/Constant';
import { drawCircle, getAreaStyleFunction } from '../../../Utils/olutils';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { layerTracker, outputMap } from '../MapInit';
import { changeMapCursor } from '../../../Utils/HelperFunctions';

const style = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
    }),
    stroke: new Stroke({
        color: 'rgba(234, 4, 68, 0.5)',
        width: 2
    }),
    image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)'
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)'
        })
    })
});

/**
 * Measure sides of features on a map
 * Reference 1: https://stackoverflow.com/questions/67782484/labeling-the-length-of-each-side-of-polygon-openlayers-3
 * Reference 2: https://openlayers.org/en/latest/examples/measure.html
 */

class MeasureTool extends Observer {
    areaLabelStyleCache: $TSFixMe;

    baseLayerProps: $TSFixMe;

    bpLotExtent: $TSFixMe;

    draw: $TSFixMe;

    invalidSpace: $TSFixMe;

    isDrawActive: $TSFixMe;

    isShiftKeyPressed: $TSFixMe;

    labelStyleCache: $TSFixMe;

    layer: $TSFixMe;

    mapObj: $TSFixMe;

    measureLayer: $TSFixMe;

    measureType: $TSFixMe;

    select: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.select = null;
        this.baseLayerProps = null;
        this.measureType = null;
        this.labelStyleCache = [];
        this.areaLabelStyleCache = [];
        this.measureLayer = null;
        this.invalidSpace = false;
        this.isShiftKeyPressed = false;
        this.layer = null;
        this.isDrawActive = false;
    }

    /**
     * On MeasureTool tool
     * @param {Array} features
     * @param {Int} measureType
     */
    on() {
        this.off();
        if (this.mapObj.isBlueprintMap) {
            this.baseLayerProps = this.mapObj.baseLayer?.getProperties() || {};
            const polygon = fromExtent(this.baseLayerProps.bp_page_extent);
            const feature = new Feature(polygon);
            this.bpLotExtent = feature;
        }

        this.measureType = MEASUREMENT_SHAPE.LINE;

        this.measureLayer = this.mapObj.getLayerById(MAP_LAYERS.MEASURE);
        const src = this.measureLayer?.getSource();
        this.mapObj.map.on('pointermove', this.checkInvalidSpace);
        this.addDrawInteraction(src);

        document.addEventListener('keydown', this.removeLastPointOnBack);
        document.addEventListener('keyup', this.handleKeyUpEvents);
    }

    checkInvalidSpace = (e: $TSFixMe) => {
        this.invalidSpace = !this.mapObj.coordsExistsInParcel(
            e.coordinate,
            this.mapObj.isBlueprintMap ? this.bpLotExtent : null
        );
        changeMapCursor(this.invalidSpace, 'not-allowed');
    };

    styleFunction = (feature: $TSFixMe) => {
        return getAreaStyleFunction({
            feature,
            areaLabelStyleCache: this.areaLabelStyleCache,
            labelStyleCache: this.labelStyleCache,
            mapInfo: { isBlueprintMap: this.mapObj.isBlueprintMap },
            style: [style],
            options: { measureArea: true, measureLength: true, measureType: this.measureType }
        });
    };

    addDrawInteraction(source: $TSFixMe) {
        let geometryType: $TSFixMe;

        switch (this.measureType) {
            case MEASUREMENT_SHAPE.LINE:
                geometryType = GEOMETRY_TYPE_STRING.LINESTRING;
                break;
            case MEASUREMENT_SHAPE.POLYGON:
                geometryType = GEOMETRY_TYPE_STRING.POLYGON;
                break;
            default:
                geometryType = GEOMETRY_TYPE_STRING.CIRCLE;
        }

        this.draw = new Draw({
            type: geometryType,
            source,
            ...(geometryType === GEOMETRY_TYPE_STRING.CIRCLE && { geometryFunction: this.createCircle }),
            style: this.styleFunction,
            condition: e => {
                const mouseClick = e.originalEvent.button;
                if (mouseClick === 2 || mouseClick === 1 || this.invalidSpace) {
                    return false;
                }
                return true;
            },
            snapTolerance: 1,
            ...(this.mapObj.enableRightClickDrag && { dragVertexDelay: 0 })
        });

        this.draw.on('drawstart', () => {
            this.isDrawActive = true;
        });
        this.draw.on('drawend', (e: $TSFixMe) => {
            this.isDrawActive = false;
            const { feature } = e;
            feature.setProperties({
                isCircle: geometryType === GEOMETRY_TYPE_STRING.CIRCLE,
                layerId: MAP_LAYERS.MEASURE
            });

            layerTracker.push(MAP_LAYERS.MEASURE, MAP_LAYERS.MEASURE);
            this.notifyObservers(TOOL_EVENT.MEASURE_TOOL);
        });

        this.mapObj.map.addInteraction(this.draw);
    }

    createCircle = (coordinates: $TSFixMe, geometry: $TSFixMe) =>
        this.isShiftKeyPressed
            ? // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
              createRegularPolygon(100)(coordinates, geometry)
            : drawCircle({ coordinates, geometry });

    changeType(measureType: $TSFixMe) {
        this.measureType = measureType;
        this.mapObj.map.removeInteraction(this.draw);

        this.labelStyleCache = [];
        this.areaLabelStyleCache = [];

        const src = this.measureLayer.getSource();
        this.addDrawInteraction(src);
    }

    /**
     * Remove last added point on pressing backspace
     * Esc abort drawing
     * @param {Event} event
     */
    removeLastPointOnBack = (event: $TSFixMe) => {
        if (event.stopPropagation) event.stopPropagation();

        const KeyID = event.keyCode;
        if (
            this.isDrawActive &&
            (event.ctrlKey || event.metaKey) &&
            KeyID === 90 &&
            this.measureType !== MEASUREMENT_SHAPE.CIRCLE
        ) {
            event.stopImmediatePropagation();
            this.draw.removeLastPoint();
        }
        if (KeyID === 27) {
            this.draw.abortDrawing();
        }
        if (KeyID === 16 && this.measureType === MEASUREMENT_SHAPE.CIRCLE) {
            this.isShiftKeyPressed = true;
        }
    };

    handleKeyUpEvents = (event: $TSFixMe) => {
        if (event.stopPropagation) event.stopPropagation();
        const KeyID = event.keyCode;
        if (KeyID === 16 && this.measureType === MEASUREMENT_SHAPE.CIRCLE) {
            this.isShiftKeyPressed = false;
        }
    };

    getMeasureToolGeojson = () => {
        const measureLayer = this.mapObj.getLayerById(MAP_LAYERS.MEASURE);
        const geojson = outputMap.getGeojsonByLayer(measureLayer);
        const layerId = measureLayer?.get('measurementsRequestId');

        return { id: layerId, geojson };
    };

    addMeasurements = (measurements: $TSFixMe) => {
        if (!measurements) return;

        const prevLayer = this.mapObj.getLayerById(MAP_LAYERS.MEASURE);
        if (prevLayer) {
            this.mapObj.removeLayer(prevLayer);
        }
        let features;
        if (measurements?.geojson) {
            features = this.mapObj.isBlueprintMap
                ? new GeoJSON().readFeatures(measurements.geojson)
                : new GeoJSON().readFeatures(measurements.geojson, {
                      dataProjection: 'EPSG:4326',
                      featureProjection: 'EPSG:3857'
                  });
        } else {
            features = new GeoJSON().readFeatures({ type: 'FeatureCollection', features: [] });
        }

        const src = new VectorSource({ features, wrapX: false });
        src.forEachFeature(f => {
            f.setProperties({ layerId: MAP_LAYERS.MEASURE }, true);
        });

        const layer = new VectorLayer({
            // @ts-expect-error TS(2345): Argument of type '{ id: string; name: string; laye... Remove this comment to see the full error message
            id: MAP_LAYERS.MEASURE,
            name: MAP_LAYERS.MEASURE,
            layerData: { name: MAP_LAYERS.MEASURE, style: this.styleFunction },
            source: src,
            style: this.styleFunction,
            measurementsRequestId: measurements?.id ?? uuid4(),
            zIndex: LAYER_INDEX.DRAW
        });
        this.mapObj.addLayer(layer);
    };

    /**
     * Clear the drawing on map and remove all selected features
     */
    clearDrawing() {
        this.layer?.getSource()?.clear();
        this.select?.getFeatures()?.clear();
        const container = document.getElementById('measure-tool-container');
        if (container) {
            container.style.display = 'none';
        }
    }

    /**
     * Off the tool
     */
    off() {
        this.mapObj.map.un('pointermove', this.checkInvalidSpace);
        this.labelStyleCache = [];
        this.areaLabelStyleCache = [];
        this.baseLayerProps = {};
        this.measureType = null;
        this.mapObj.map.removeInteraction(this.draw);
        document.removeEventListener('keydown', this.removeLastPointOnBack);
        document.removeEventListener('keyup', this.handleKeyUpEvents);
        this.isDrawActive = false;
    }
}
export default MeasureTool;
