import cloneDeep from 'lodash/cloneDeep';
import { createHash } from 'crypto';

import { containsIgnoreCase } from '../../Utils/HelperFunctions';
import {
    FILL_PATTERNS,
    GEOMETRY_NAME_SINGLE,
    GEOMETRY_TYPE_ENUM,
    MEASUREMENT_VIEW_ENUM,
    POINT_SHAPES,
    TAG_ASSIGN_MODE
} from '../../Constants/Constant';
import { roundNum } from '../../Utils/pureHelpers';
import { mapObj } from '../../MainPage/OlMap/MapInit';

export const transformAssignedTags = (tags: $TSFixMe) => {
    const transformedTags = {};
    // tags:[{tagTypeId:{tagId,subTagId},tagTypeId:{tagId,subTagId}},{}]
    tags?.forEach((tags_info: $TSFixMe) => {
        // tags_info = {tagTypeId:{tagId,subTagId},tagTypeId:{tagId,subTagId}}
        Object.keys(tags_info)?.forEach(tagTypeId => {
            const { tagId, subtagId = null, isFeatureTag = false } = tags_info[tagTypeId] || {};
            // if transformedTags has already tagTypeId inside it
            if (Object.prototype.hasOwnProperty.call(transformedTags, tagTypeId)) {
                // we'll be updating isFeatureTag property of transformedTags
                // if isFeatureTag comes true
                if (isFeatureTag) {
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    transformedTags[tagTypeId].isFeatureTag = isFeatureTag;
                }
                // if there are some tags already assiged to this tagtypeid
                // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                if (transformedTags[tagTypeId].tags) {
                    // if tagId is already present in tags
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    if (Object.prototype.hasOwnProperty.call(transformedTags[tagTypeId].tags, tagId)) {
                        //    if user has selected subtag
                        if (subtagId) {
                            // if some subtags are already present there
                            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                            if (transformedTags[tagTypeId].tags[tagId].subtags) {
                                // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                                transformedTags[tagTypeId].tags[tagId].subtags = {
                                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                                    ...transformedTags[tagTypeId].tags[tagId].subtags,
                                    [subtagId]: {
                                        subtagId
                                    }
                                };
                            } else {
                                // assign subTagId to given tagTypeId, tagId
                                // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                                transformedTags[tagTypeId].tags[tagId].subtags = {
                                    [subtagId]: {
                                        subtagId
                                    }
                                };
                            }
                        }
                    } else {
                        // selected tagId is not present in tagType yet
                        // add tagId to this tagType
                        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        transformedTags[tagTypeId].tags = {
                            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                            ...transformedTags[tagTypeId].tags,
                            [tagId]: {
                                tagId,
                                subtags: null
                            }
                        };
                        // if user has selected subtag also
                        // add subtagId
                        if (subtagId) {
                            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                            transformedTags[tagTypeId].tags[tagId].subtags = {
                                [subtagId]: {
                                    subtagId
                                }
                            };
                        }
                    }
                }
            } else {
                // there is no tagType Id
                // assign tagTypeId
                // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                transformedTags[tagTypeId] = {
                    tagTypeId,
                    isFeatureTag,
                    tags: null
                };

                // if in input some tagId is there add it
                if (tagId) {
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    transformedTags[tagTypeId].tags = {
                        [tagId]: {
                            tagId,
                            subtags: null
                        }
                    };
                }

                // if some subTag is there assign it
                if (subtagId) {
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    transformedTags[tagTypeId].tags[tagId].subtags = {
                        [subtagId]: {
                            subtagId
                        }
                    };
                }
            }
        });
    });

    return transformedTags;
};

export const checkConflict = (selectedData: $TSFixMe) => {
    // if no tags are selected than there can't be any conflict
    if (!selectedData?.tags) return false;

    // checking for tag conflicting
    if (Object.keys(selectedData?.tags).length > 1) {
        return true;
    }

    // checking for subtag conflicting
    const hasConflictingSubtags = Object.values(selectedData?.tags || {}).some(
        (tag: $TSFixMe) => tag.subtags && Object.keys(tag.subtags).length > 1
    );
    if (hasConflictingSubtags) {
        return true;
    }
    return false;
};

export const getColumnsMetaData = ({ tagTypeList, hideMeasurements = false, output_mode }: $TSFixMe) => {
    const columnsMetaData = [
        {
            name: 'Property features',
            value: 'featureName',
            canHide: false,
            isVisible: true,
            isTagType: false,
            tagTypeId: null
        },
        {
            name: 'Description',
            value: 'description',
            canHide: true,
            isVisible: true,
            isTagType: false,
            tagTypeId: null
        },
        {
            name: 'Geometry type',
            value: 'geometryType',
            canHide: true,
            isVisible: true,
            isTagType: false,
            tagTypeId: null
        },
        {
            name: 'Measurement',
            value: 'measurement',
            canHide: !hideMeasurements,
            isVisible: !hideMeasurements,
            isTagType: false,
            tagTypeId: null
        },
        {
            name: 'Unit',
            value: 'unit',
            canHide: !hideMeasurements,
            isVisible: !hideMeasurements,
            isTagType: false,
            tagTypeId: null
        },
        {
            name: 'Attributes',
            value: 'attributes',
            canHide: !hideMeasurements,
            isVisible: !hideMeasurements,
            isTagType: false,
            tagTypeId: null
        },
        {
            name: "Attribute's measurement",
            value: 'attribute_based_measurements',
            canHide: !hideMeasurements,
            isVisible: !hideMeasurements,
            isTagType: false,
            tagTypeId: null
        },
        {
            name: 'Present on sheets',
            value: 'presentOnSheets',
            canHide: output_mode === MEASUREMENT_VIEW_ENUM.TAKEOFF_LEVEL,
            isVisible: output_mode === MEASUREMENT_VIEW_ENUM.TAKEOFF_LEVEL,
            isTagType: false,
            tagTypeId: null
        }
    ];
    // add tagTypes
    tagTypeList.forEach((_tagType: $TSFixMe) => {
        columnsMetaData.push({
            name: _tagType.name,
            value: _tagType?.value,
            canHide: true,
            isVisible: true,
            isTagType: true,
            tagTypeId: _tagType?.value
        });
    });
    return columnsMetaData;
};

export const filterTagsData = (textSearched: $TSFixMe, tags: $TSFixMe) => {
    const query = textSearched.trim();
    if (!query) return tags;
    const clonedData = cloneDeep(tags);
    return clonedData.filter((tagType: $TSFixMe) => {
        const matchedTags = tagType.tags.filter((tag: $TSFixMe) => {
            const matchedSubTags = tag.subtags.filter((subtag: $TSFixMe) => containsIgnoreCase(subtag?.name, query));

            // if any subtag matches we'll show only matched subtags with its parent tag also in searched data
            if (matchedSubTags.length > 0) {
                // eslint-disable-next-line no-param-reassign
                tag.subtags = matchedSubTags;
                return true;
            }

            // if no matches we'll check for tagName
            return containsIgnoreCase(tag?.name, query);
        });

        // if any tag matches we'll show only matched tags with its parent tagType also in searched data
        if (matchedTags.length > 0) {
            // eslint-disable-next-line no-param-reassign
            tagType.tags = matchedTags;
            return true;
        }
        // if no matches we'll check for tagTypeName
        return containsIgnoreCase(tagType?.name, query);
    });
};

// operation type for tags query
export const OPERATION_TYPE = {
    OR: 1,
    AND: 2
};
// Labels for showing type of query opertion
export const OPERATION_TYPE_VALUES = {
    1: 'Or',
    2: 'And'
};
// type of sorting in tags table columns
export const SORT_TYPE = {
    ASC: 1,
    DEC: 2
};

const MEASUREMENT_AGGREGATION_KEY_MAPPING = {
    1: ['count'],
    2: ['length'],
    3: ['area'],
    4: ['area', 'length', 'count', 'volume', 'weight', 'weight_ton']
};

/**
 *
 * @param {Object} properties
 * measurement properties for a given geometry
 * @param {Array} measurement_list
 * propetries of geometry that we want in aggregate
 * @returns
 * object contains only properties we want to aggregate and unit of that aggregate
 */
const getTransformedMeasurement2 = (properties: $TSFixMe, measurement_list = [], feature_type: $TSFixMe) => {
    const newMeasurement = {};
    measurement_list.forEach(_item => {
        // in case of count(Points) default value is 1 and default unit is count
        // for others we are collecting values from properties and associating unit directly from measurement_list
        // for feature_type = 4(numerical feature) initilize count as value of count store in properties
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newMeasurement[_item.name] = {
            value:
                // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
                _item.name === 'count'
                    ? feature_type === GEOMETRY_TYPE_ENUM.NUMERICAL
                        ? // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
                          properties[_item.name]
                        : 1
                    : // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
                      properties[_item.name]
                      ? // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
                        properties[_item.name]
                      : 0,
            // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
            unit: _item.name === 'count' ? 'count' : _item.unit
        };
    });
    return newMeasurement;
};

/**
 *
 * @param {Array} output
 * array of outputs which contains list of features and their associated geometry inside feature object
 * @param {Object} query
 * tagsQuery which user have applied - contains information about type of operation and combination of tags/tagtype/subtags
 * @returns
 * list of filtered geometries in aggregated form if two geometries are equivalant and we are merging their measurement
 * A. Filtering happens based on tagQuery applied
 *      1. if tagQuery type is AND we filters only those geometry which contains ALL the tagType(tagTypes in which at least one tag or subtag selected)
 *      2. in case of OR - we filters only those geometry which contains ANY the tagType(tagTypes in which at least one tag or subtag selected)
 * B. Merging happens after filtering is done
 *      B. geometry1 and geometry2 are equivalant following contains meets
 *      1. both belongs to same feature (same featureId)
 *      2. both have same combination of (tagType1, tagType2, ..., tag1, tag2,..., subtag1, subtag2, ...) => tagType, tag, subtags are extracted from geometry tag_info list which contains all the tag info assigned to the geometry
 */
export const getFilteredGeometries = (output = [], query = {}) => {
    const filteredGeometriesOutput = [];

    // maintaing featureMap(feature_id, featureDetails)
    // featureDetails contains all the required details of feature, i.e featureName, featureType, isAssemblyFeature etc.
    const featureMap = new Map();
    // maintaing assemblyMap(feature_id, materialsDetails)
    // materialDetails is list of all the materials associated with the given assembly feature
    const assemblyMap = new Map();

    // iterating the output array to extract feature and all their associated geometry
    for (let i = 0; i < output.length; i++) {
        const currentFeature = output[i];

        const { measurements, id, attributes, attribute_based_measurements, assemblies } = currentFeature || {};
        const {
            name,
            description,
            feature_id,
            geometry_type,
            is_assembly_feature,
            present_on_sheets = [],
            default_tags: featureTags = {}
            // @ts-expect-error TS(2339): Property 'feature' does not exist on type 'never'.
        } = currentFeature?.feature || {};

        // @ts-expect-error TS(2339): Property 'style' does not exist on type 'never'.
        const { pattern: patternEnum, color } = currentFeature?.style || {};
        const pattern =
            geometry_type === GEOMETRY_TYPE_ENUM.POLYGON
                ? FILL_PATTERNS[patternEnum]?.image
                : geometry_type === GEOMETRY_TYPE_ENUM.POINT
                  ? POINT_SHAPES[patternEnum]?.image
                  : false;

        const featureDetails = {
            name,
            description,
            id,
            feature_id,
            geometry_type,
            is_assembly_feature,
            measurement_list: measurements,
            attributes,
            attribute_based_measurements,
            color,
            pattern: pattern || false,
            presentOnSheets: present_on_sheets
        };

        // if feature is assembly store its materials in assemblyMap
        if (is_assembly_feature) {
            assemblyMap.set(feature_id, assemblies);
        }
        // store featureDetails in featureMap
        featureMap.set(feature_id, featureDetails);

        // iterate feature geojson to extract out all the geometry associated with the give feature
        // @ts-expect-error TS(2339): Property 'output_geojson' does not exist on type '... Remove this comment to see the full error message
        const features = currentFeature?.output_geojson?.features || [];

        for (let j = 0; j < features.length; j++) {
            const feature = features[j];
            // ignore invisible geometry(filtered out by tagQuery which user has selected)
            const geomTags = feature?.properties?.tags_info || {};
            // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
            const mergedTags = mergeFeatAndGeomTags(featureTags, [geomTags]);

            if (!isGeomOrFeatureVisible(mergedTags, query)) {
                continue;
            }
            // building geometry object which contains infomation about itself and the which feature it is assocaited with and its tagsInfo
            const currentGeometry = {
                type: feature?.geometry?.type,
                feature_type: geometry_type,
                measurement: getTransformedMeasurement2(feature?.properties, measurements, geometry_type),
                feature_id,
                tags_info: mergedTags
            };

            // adding current geometry in list of extracted geometries
            filteredGeometriesOutput.push(currentGeometry);
        }
    }
    // returning list of extracted geometries, featureMap, assemblyMap
    return { filteredGeometriesOutput, featureMap, assemblyMap };
};

/**
 *
 * @param {Object} storedObject
 * @param {Object} currentGeometry
 * @param {Map} featureMap
 * @returns
 * new storeObject after merging currentGeometry measurements in storeObject measurements
 */
export const getUpdatedMesurement = (storedObject: $TSFixMe, currentGeometry: $TSFixMe, featureMap: $TSFixMe) => {
    const measurementList = featureMap.get(currentGeometry.feature_id).measurement_list;
    for (let i = 0; i < measurementList.length; i++) {
        if (storedObject.aggregate_measurement[measurementList[i].name]) {
            // eslint-disable-next-line no-param-reassign
            storedObject.aggregate_measurement[measurementList[i].name] =
                parseFloat(storedObject.aggregate_measurement[measurementList[i].name]) +
                parseFloat(currentGeometry.measurement[measurementList[i].name].value);
        } else {
            // eslint-disable-next-line no-param-reassign
            storedObject.aggregate_measurement[measurementList[i].name] = parseFloat(
                currentGeometry.measurement[measurementList[i].name].value
            );
        }
        storedObject.aggregate_measurement[measurementList[i].name] = roundNum(
            storedObject.aggregate_measurement[measurementList[i].name],
            2
        );
    }
    return storedObject;
};

/**
 *
 * @param {Boolean} acc
 * @param {Boolean} curr
 * @param {Enum} operation
 * @returns
 * performs And or OR operation on param1 and param2 based on operation type
 */
const performOperation = (acc: $TSFixMe, curr: $TSFixMe, operation: $TSFixMe) => {
    if (operation === OPERATION_TYPE.AND) {
        return acc && curr;
    }
    return acc || curr;
};

/**
 *
 * @param {Object} tags_info
 * tags_info object which we associated with each geometry
 * @param {Object} query
 * tagsQuery which user have applied
 * @returns
 * true/false depending on if geometry is visible or not based on tagQuery and tags_info
 */

export const isGeomOrFeatureVisible = (tags_info: $TSFixMe, query = {}) => {
    // @ts-expect-error TS(2339): Property 'tagsInfo' does not exist on type '{}'.
    if (!query || !Object.keys(query.tagsInfo)?.length) {
        return true;
    }
    // @ts-expect-error TS(2339): Property 'type' does not exist on type '{}'.
    let result = query?.type !== OPERATION_TYPE.OR;

    // @ts-expect-error TS(2339): Property 'tagsInfo' does not exist on type '{}'.
    const tagTypeList = Object.keys(query.tagsInfo);
    const currentGeomTags = tags_info;

    for (let i = 0; i < tagTypeList.length; i++) {
        const currentTagType = tagTypeList[i];
        if (Object.prototype.hasOwnProperty.call(currentGeomTags, currentTagType)) {
            const geomTagId = currentGeomTags[currentTagType]?.tagId;
            const geomSubTagId = currentGeomTags[currentTagType]?.subtagId;

            if (geomTagId) {
                // checking if tag is matching with filteredTag
                // @ts-expect-error TS(2339): Property 'tagsInfo' does not exist on type '{}'.
                let isPresent = Object.prototype.hasOwnProperty.call(query.tagsInfo[currentTagType], geomTagId);

                // checking if user has selected any subtag from filter modal
                // @ts-expect-error TS(2339): Property 'tagsInfo' does not exist on type '{}'.
                const querySubtags = query.tagsInfo[currentTagType]?.[geomTagId] || [];
                if (querySubtags?.length) {
                    // if user has selected subtags and geom has also any subtag assigned
                    // then we'll be filtering on subtag level
                    if (geomSubTagId) {
                        isPresent = isPresent && querySubtags.includes(geomSubTagId);
                    } else {
                        // otherwise we'll skip the geometry
                        isPresent = false;
                    }
                }

                // @ts-expect-error TS(2339): Property 'type' does not exist on type '{}'.
                result = performOperation(result, isPresent, query.type);
            } else {
                // @ts-expect-error TS(2339): Property 'type' does not exist on type '{}'.
                result = performOperation(result, false, query.type);
            }
        } else {
            // if current tagTypeId is not present in geom, dont consider that geom
            // @ts-expect-error TS(2339): Property 'type' does not exist on type '{}'.
            result = performOperation(result, false, query.type);
        }
    }
    return result;
};

export const considerSubtag = ({ tagView, currentTagType }: $TSFixMe) => {
    if (!tagView || (tagView && !Object.prototype.hasOwnProperty.call(tagView, currentTagType))) {
        return true;
    }
    if (tagView[currentTagType].isSubTagView) {
        return true;
    }
    if (tagView[currentTagType].isSubTagView === false) {
        return false;
    }
    return false;
};

export const getHashKeyString = (
    currentGeometry: $TSFixMe,
    hiddenColumns: $TSFixMe,
    tagView: $TSFixMe,
    tagMap: $TSFixMe,
    subtagMap: $TSFixMe
) => {
    let newKey = `${currentGeometry.type}_${currentGeometry.feature_id}`;

    const uuidList = [];

    const tagTypeIdList = Object.keys(currentGeometry.tags_info);

    for (let i = 0; i < tagTypeIdList.length; i++) {
        const currentTagTypeId = tagTypeIdList[i];
        const currentTag = currentGeometry.tags_info[currentTagTypeId].tagId;
        const currentSubTag = currentGeometry.tags_info[currentTagTypeId].subtagId;

        if (currentTagTypeId && !hiddenColumns.includes(currentTagTypeId)) {
            uuidList.push(currentTagTypeId);
            if (currentTag && Object.prototype.hasOwnProperty.call(tagMap, currentTag)) {
                uuidList.push(currentTag);
            }
            if (
                currentSubTag &&
                considerSubtag({ tagView, currentTagTypeId }) &&
                Object.prototype.hasOwnProperty.call(subtagMap, currentSubTag)
            ) {
                uuidList.push(currentSubTag);
            }
        }
    }
    uuidList.sort();
    for (let i = 0; i < uuidList.length; i++) {
        newKey = `${newKey}_${uuidList[i]}`;
    }
    return newKey;
};
export const getTextFilteredData = (currentGeometries: $TSFixMe, text: $TSFixMe) => {
    return currentGeometries.filter((currentGeometry: $TSFixMe) => {
        return containsIgnoreCase(currentGeometry.featureName?.name || '', text || '');
    });
};

export const getSubtagName = ({ subTagMap, tagTypeId, subtagId, tagView }: $TSFixMe) => {
    if (considerSubtag({ tagView, currentTagType: tagTypeId })) {
        return subTagMap[subtagId]?.name;
    }
    return undefined;
};

export const getRowMeasurementValue = (measurement: $TSFixMe, feature_type: $TSFixMe) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const key = MEASUREMENT_AGGREGATION_KEY_MAPPING[feature_type].find((val: $TSFixMe) =>
        Object.keys(measurement).includes(val)
    );
    return key ? measurement[key] : '';
};

export const getRowMeasurementUnit = (measurement: $TSFixMe, feature_type: $TSFixMe) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const key = MEASUREMENT_AGGREGATION_KEY_MAPPING[feature_type].find((val: $TSFixMe) =>
        Object.keys(measurement).includes(val)
    );
    return key ? measurement[key]?.unit : '';
};

export const getSortFilteredData = (currentGeometries: $TSFixMe, sort: $TSFixMe) => {
    if (sort.columnId && sort.order) {
        currentGeometries.sort((geomA: $TSFixMe, geomB: $TSFixMe) => {
            let tempResult;
            if (geomA[sort.columnId]?.name && geomB[sort.columnId]?.name) {
                if (sort.order === SORT_TYPE.ASC) {
                    tempResult = geomA[sort.columnId].name < geomB[sort.columnId].name ? -1 : 1;
                } else {
                    tempResult = geomA[sort.columnId].name > geomB[sort.columnId].name ? -1 : 1;
                }

                return tempResult;
            }

            if (sort.order === SORT_TYPE.ASC) {
                tempResult = geomA[sort.columnId] < geomB[sort.columnId] ? -1 : 1;
            } else {
                tempResult = geomA[sort.columnId] > geomB[sort.columnId] ? -1 : 1;
            }

            return tempResult;
        });
    }
    return currentGeometries;
};

export const getMergedDataBasedOnTagView = (
    currentGeometries: $TSFixMe,
    tagView = {},
    hiddenColumns = [],
    featureMap: $TSFixMe,
    tagMap: $TSFixMe,
    subtagMap: $TSFixMe
) => {
    const filteredGeometries = [];
    const hashMap = new Map();
    for (let i = 0; i < currentGeometries.length; i++) {
        const currentGeometry = currentGeometries[i];
        const hashKey = getHashKeyString(currentGeometry, hiddenColumns, tagView, tagMap, subtagMap);
        const hashedKeyString = createHash('sha256').update(hashKey).digest('base64');

        if (hashMap.has(hashedKeyString)) {
            const storedObject = hashMap.get(hashedKeyString);
            // eslint-disable-next-line no-unused-vars
            const updatedObject = getUpdatedMesurement(storedObject, currentGeometry, featureMap);
        } else {
            const featureDetails = featureMap.get(currentGeometry.feature_id);
            const newGeometry = {
                ...cloneDeep(currentGeometry),
                featureName: featureDetails.name,
                description: featureDetails.description,
                geometryType: GEOMETRY_NAME_SINGLE[featureDetails.geometry_type],
                aggregate_measurement: {}
            };
            const updatedObject = getUpdatedMesurement(newGeometry, currentGeometry, featureMap);
            hashMap.set(hashedKeyString, updatedObject);
            filteredGeometries.push(updatedObject);
        }
    }
    return filteredGeometries;
};

export const isTagTypeUtilisedInTakeoff = ({ outputs, tagTypeId }: $TSFixMe) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const output of outputs) {
        const featureTags = output?.feature?.default_tags || {};
        const geoms = output?.output_geojson?.features || [];
        if (!geoms.length) continue;
        const geomsTags = geoms.flatMap((feat: $TSFixMe) => feat?.properties?.tags_info || []);
        const mergedTags = mergeFeatAndGeomTags(featureTags, geomsTags);
        if (Object.prototype.hasOwnProperty.call(mergedTags, tagTypeId)) return true;
    }
    return false;
};

export const constructTagsMap = (tags: $TSFixMe) => {
    const tagTypeMap = {};
    const tagMap = {};
    const subTagMap = {};

    for (let i = 0; i < tags.length; i++) {
        const tagType = tags[i];
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        tagTypeMap[tagType.id] = {
            id: tagType.id,
            name: tagType.name
        };
        for (let j = 0; j < tagType.tags.length; j++) {
            const tag = tagType.tags[j];
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            tagMap[tag.id] = {
                id: tag.id,
                name: tag.name,
                color: tag.color,
                bg_color: tag.bg_color
            };
            for (let k = 0; k < tag.subtags.length; k++) {
                const subtag = tag.subtags[k];

                // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                subTagMap[subtag.id] = {
                    id: subtag.id,
                    name: subtag.name
                };
            }
        }
    }
    return {
        tagTypeMap,
        tagMap,
        subTagMap
    };
};

export const mergeFeatAndGeomTags = (featTags = {}, geomTags = []) => {
    const mergedTags = cloneDeep(featTags);
    // eslint-disable-next-line no-restricted-syntax
    for (const key in mergedTags) {
        // @ts-expect-error
        if (Object.prototype.hasOwnProperty.call(mergedTags, key) && typeof mergedTags[key] === 'object') {
            // @ts-expect-error
            mergedTags[key].isFeatureTag = true;
        }
    }
    if (!geomTags?.length) return mergedTags;
    geomTags.forEach(geomTag => {
        Object.keys(geomTag || {})?.forEach(tagTypeId => {
            // if feature has already that tagType
            // we'll give prefrence to that one
            // @ts-expect-error
            if (!mergedTags[tagTypeId]) {
                // @ts-expect-error
                mergedTags[tagTypeId] = geomTag[tagTypeId];
            }
        });
    });

    return mergedTags;
};

export const calculateAggregateMeasurement = (features: $TSFixMe, key: $TSFixMe, alternateKey: $TSFixMe) =>
    features.reduce((ac: $TSFixMe, el: $TSFixMe) => ac + (el[key] || el[alternateKey] || 0), 0);

export const readTagsFromFandG = ({ tagAssignMode, tagsSelectedFeatures, tagsSelectedLayers }: $TSFixMe) => {
    // this function returns the array of tagsInfo
    if (tagAssignMode === TAG_ASSIGN_MODE.FEATURE_LEVEL)
        return tagsSelectedLayers.map((layerId: $TSFixMe) => {
            const layer = mapObj.getLayerById(layerId);
            return layer.getProperties()?.layerData?.feature?.default_tags || {};
        });

    return tagsSelectedFeatures.map((feat: $TSFixMe) => {
        const layerId = feat?.get('layerId');
        const layer = mapObj.getLayerById(layerId);
        const geomTags = feat.getProperties().tags_info || {};
        const featureTags = layer?.getProperties()?.layerData?.feature?.default_tags || {};

        // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
        return mergeFeatAndGeomTags(featureTags, [geomTags]);
    });
};

export const getRandomHexColor = () => {
    const randomNum = Math.floor(Math.random() * 16777216);
    const hexColor = randomNum.toString(16).padStart(6, '0');

    return `#${hexColor}`;
};
